<template>
    <div>
        <ul class="md-sidebar_active_line">
            <span class="text-gray text-base">Class Overview</span>

            <md-divider class="my-2"></md-divider>
            <li class="flex justify-between">
                <div class="inline-flex align-center">
                    <md-icon class="m-0 search-icon text-purply-magenta">schedule</md-icon><span class="md-acive_icon text-capitalize ml-2 text-base text-black">Total Hours</span>
                </div>

                <div>
                    <span class="text-bold text-base">{{ senitizeDurationToHour }}</span>
                </div>
            </li>
            <md-divider class="my-2"></md-divider>

            <li class="flex justify-between">
                <div class="inline-flex align-center">
                    <md-icon class="m-0 search-icon text-sky">book</md-icon> <span class="md-acive_icon text-capitalize ml-2 text-base text-black">Total Modules</span>
                </div>

                <div>
                    <span class="text-bold text-base">{{ totalModules }}</span>
                </div>
            </li>
            <md-divider class="my-2"></md-divider>

            <li class="flex justify-between">
                <div class="inline-flex align-center">
                    <md-icon class="m-0 search-icon text-autumn-blaze" style="transform: rotate(-45deg);">attachment</md-icon><span class="md-acive_icon text-capitalize ml-2 text-base text-black">Total Chapters</span>
                </div>

                <div>
                    <span class="text-bold text-base">{{ totalChapters }}</span>
                </div>
            </li>
            <md-divider class="my-2"></md-divider>

            <li class="flex justify-between">
                <div class="inline-flex align-center">
                    <md-icon class="m-0 search-icon text-red">cases</md-icon> <span class="md-acive_icon text-capitalize ml-2 text-base text-black">Total Materials</span>
                </div>

                <div>
                    <span class="text-bold text-base">{{ totalMaterials }}</span>
                </div>
            </li>
            <md-divider class="my-2"></md-divider>

            <li class="flex justify-between">
                <div class="inline-flex align-center">
                    <md-icon class="m-0 search-icon text-irish-blue">content_paste</md-icon> <span class="md-acive_icon text-capitalize ml-2 text-base text-black">Total Practice Tests</span>
                </div>

                <div>
                    <span class="text-bold text-base">{{ totalPracticeTest }}</span>
                </div>
            </li>
            <md-divider class="my-2"></md-divider>

            <li class="flex justify-between">
                <div class="inline-flex align-center">
                    <md-icon class="m-0 search-icon text-purply-magenta">article</md-icon><span class="md-acive_icon text-capitalize ml-2 text-base text-black">Total Quizes</span>
                </div>

                <div>
                    <span class="text-bold text-base">{{ totalQuizTest }}</span>
                </div>
            </li>
            <md-divider class="my-2"></md-divider>
        </ul>
    </div>
</template>

<script>
    import { secondToHour } from '@/utils';
    export default {
        props: {
            item: {
                type: Object,
                default: null,
            }
        },
        computed: {
            senitizeDurationToHour() {
                if(this.item?.total_duration) {
                    return secondToHour(this.item.total_duration)
                }
                return '0';
            },
            totalModules(){
                if(this.item?.total_modules) {
                    return this.item.total_modules;
                }

                return "0"
            },
            totalChapters(){
                if(this.item?.total_chapters) {
                    return this.item.total_chapters;
                }

                return "0"
            },
            totalMaterials(){
                if(this.item?.total_materials) {
                    return this.item.total_materials;
                }

                return "0"
            },
            totalPracticeTest(){
                if(this.item?.total_practice_tests) {
                    return this.item.total_practice_tests;
                }

                return "0"
            },
            totalQuizTest(){
                if(this.item?.total_quizes) {
                    return this.item.total_quizes;
                }

                return "0"
            }
        }
    }
</script>
