<template>
    <section class="flex">
        <div v-if="loading">
            <Loader />
        </div>
        
        <div class="border rounded-lg border-solid border-light-blue w-75p">
            <div class="flex align-start px-3 py-4">
                <div class=" w-80p flex justify-between">
                    <div class="inline-flex">
                        <md-icon class="mr-2 text-blue text-xl md-theme-default">
                            book
                        </md-icon>
                        <span class="mr-2 text-lg font-semibold text-black">{{ title }}</span>
                    </div>
    
                    <div class="inline-flex">
                        <span class="text-xs text-blue bg-trans-blue font-semibold px-1 text-center align-center flex rounded">{{ senitizeDurationToHour }} Hours</span>
                        <span class="ml-1 text-xs text-tahiti-gold bg-trans-tahiti-gold font-semibold px-1 text-center align-center flex rounded">{{ totalMaterials }} Materials</span>
                        <span class="ml-1 text-xs text-irish-blue bg-trans-irish-blue font-semibold px-1 text-center align-center flex rounded">{{ totalPracticeTest }} Practice test</span>
                        <span class="ml-1 text-xs text-purply-magenta bg-trans-purply-magenta font-semibold px-1 text-center align-center flex rounded">{{ totalQuizTest }} Quiz</span>
                    </div>
                </div>
    
                <div class="cursor w-20p flex justify-end">
                    <p class="text-sm font-medium font-inter">
                       <span class="text-blue text-xs mr-3 font-semibold">{{ totalChapters }} Chapters</span> 
                        
                        <md-icon v-if="!isShow" @click.native="onShowChapterSummary" class="border-2 font-semibold rounded-full border-solid text-blue text-sm md-theme-default">
                            add
                        </md-icon>
                        <md-icon v-else @click.native="isShow = false" class="border-2 font-semibold rounded-full border-solid text-blue text-sm md-theme-default">
                            remove
                        </md-icon>
                    </p>
                </div>
            </div>
    
            <p class="text-base my-4 px-1 line-height-27 px-3" v-html="moduleObject.description"></p>
    
            <!-- chapters -->
            <div v-if="isShow">
                <div v-for="(chapter, index) in moduleObject.chapters" :key="index" class="px-3 py-2 border border-t-solid border-bisque" :class="index % 2 ? 'bg-white-heat' : 'bg-white-fever'">
                    <div v-if="chapter" class="flex align-center justify-between px-1 py-1 pointer" @click="goToChapterDetails(chapter)">
                        <div class="flex flex-row" style="max-width: 38% !important;">
                            <md-icon class="mr-1 text-blue text-sm md-theme-default text-autumn-blaze bg-wheat rounded-full" style="transform: rotate(45deg);">
                                attach_file
                            </md-icon>
                            <div class="mt-1">
                                <div>
                                    <h2 class="text-xs text-autumn-blaze">Chapter {{ index + 1 }}</h2>
                                </div>
                                <div class="mt-1">
                                    <p class="text-sm font-semibold text-black">{{ chapter?.title }}</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="cursor inline-flex">
                            <p class="text-sm font-medium font-inter flex align-center mr-1">
                                <md-icon class="m-0 text-sm text-purply-magenta">schedule</md-icon><span class="text-xs">{{ senitizeChapterDurationToHour(chapter.duration) }} Hours</span> 
                            </p>
    
                            <p class="text-sm font-medium font-inter flex align-center mr-1">
                                <md-icon class="text-red text-sm md-theme-default">cases</md-icon> 
                                <span class="text-xs">{{ chapter.total_materials }}</span>
                            </p>
    
                            <p class="text-sm font-medium font-inter flex align-center mr-1">
                                <md-icon class="text-irish-blue text-sm md-theme-default">content_paste</md-icon> 
                                <span class="text-xs">{{ chapter.total_practice_tests }}</span>
                            </p>
    
                            <p class="text-sm font-medium font-inter flex align-center">
                                <md-icon class="text-purply-magenta text-sm md-theme-default">article</md-icon>
                                <span class="text-xs">{{ chapter.total_quizes }}</span>
                            </p>
                        </div>
    
                        <div class="cursor flex justify-end">
                            <span class="flex align-center justify-between">
                                <p class="font-semibold">Completion Rate</p>
                                <md-progress-bar class="rounded mx-1 w-12" style="margin-top: 3px;" md-mode="determinate" :md-value="chapter.completion_rate"></md-progress-bar>
                                <small class="text-xs font-semibold">{{ chapter.completion_rate }}%</small>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="px-3 py-2 bg-light-blue rounded-br rounded-bl">
                <LinearProgressBar :progressValue="completion_rate" label="Completion Rate"/>
            </div>
        </div>

        <div class="w-20p ml-8 mt-1">
            <md-icon  class="text-3xl pointer" 
                :class="bookMarkColorClass"
                @click.native="onUpdateBookMark"
            >
                bookmark
            </md-icon>
        </div>
    </section>
</template>

<script>
import LinearProgressBar from "@/components/atom/LinearProgressBar.vue";
import { secondToHour } from '@/utils';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Loader from '@/components/atom/loader.vue';

export default {
    name: "Modules",
    components: {
        LinearProgressBar,
        Loader,
    },
    props: {
        item: {
            type: Object,
            default: null,
        },
        moduleObject: {
            type: Object,
            default: null,
        },
        index: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            isShow: false,
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            getClassDetail: "onlineClassStudentPart/getClassDetail",
        }),
        bookMarkColorClass() {
            if(this.moduleObject && this.moduleObject?.is_bookmarked) {
                return 'text-tahiti-gold'
            }

            return 'text-gray-500';
        },
        title() {
            if(this.moduleObject && this.moduleObject.title.length > 18) {
                return this.moduleObject.title.substring(0, 18) + "...";
            } else if(this.moduleObject && this.moduleObject.title) {
                return this.moduleObject.title;
            }
            return  "";
        },
        completion_rate() {
            if(this.moduleObject?.completion_rate) {
                return this.moduleObject.completion_rate;
            }

            return 0
        },
        totalChapters(){
            if(this.moduleObject?.total_chapters) {
                return this.moduleObject.total_chapters;
            }

            return "00"
        },
        senitizeDurationToHour() {
            if(this.moduleObject?.total_duration) {
                return secondToHour(this.moduleObject.total_duration)
            }
            return '0';
        },
        totalMaterials(){
            if(this.moduleObject?.total_materials) {
                return this.moduleObject.total_materials;
            }

            return "0"
        },
        totalPracticeTest(){
            if(this.moduleObject?.total_practice_tests) {
                return this.moduleObject.total_practice_tests;
            }

            return "0"
        },
        totalQuizTest(){
            if(this.moduleObject?.total_quizes) {
                return this.moduleObject.total_quizes;
            }

            return "0"
        },
    },
    methods: {
        ...mapMutations({
			setShowSnackbar: "setShowSnackbar",
            setClassDetail: "onlineClassStudentPart/setClassDetail"
		}),
        ...mapActions({
            fetchChapterSummaryByClassIdAndModuleId: "onlineClassStudentPart/fetchChapterSummaryByClassIdAndModuleId",
            onSetBookMark: "onlineClassStudentPart/onSetBookMark"
        }),
        onUpdateBookMark(){
            let data = {
                online_class_id: this.getClassDetail.id,
                module_id: this.moduleObject.id,
                chapter_id: "",
                part_id: "",
                section_id: "", 
            };

            this.loading = true;

            this.onSetBookMark(data)
            .then((response) => {
                this.setShowSnackbar(response.message);
                let classDetails = JSON.parse(JSON.stringify(this.getClassDetail));

                if(this.index > -1) {
                    classDetails.modules[this.index].is_bookmarked = this.moduleObject.is_bookmarked ? 0 : 1;
                    this.setClassDetail(classDetails);
                }
            })
            .catch((error) => {
                this.setShowSnackbar(error?.message);
            })
            .finally(() => this.loading = false)
        },
        senitizeChapterDurationToHour(duration) {
            if(duration) {
                return secondToHour(duration)
            }
            return '0';
        },
        goToChapterDetails(chapter) {
            this.$router.push({
                name: 'student.onlineClass.chapter.details',
                params: {
                    id: this.$route.params.id,
                },
                query: {
                    moduleId: this.moduleObject.id,
                    chapterId: chapter.id,
                }
            });
        },
        onShowChapterSummary() {
            if(!this.moduleObject.chapters) {
                this.loading = true;
                
                let idObj = {
                    classId: this.item.id,
                    moduleId: this.moduleObject.id,
                }

                this.fetchChapterSummaryByClassIdAndModuleId(idObj)
                    .then(() => {
                        this.isShow = true;
                    })
                    .catch((error) => {
                        console.log("error", error);
                        this.setShowSnackbar(error?.message);
                    })
                    .finally(() => this.loading = false);
            } else {
                this.isShow = true;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>