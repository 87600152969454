<template>
  <div class="accordion">
      <div class="mb-3" :class="borderClasses">
        <div
          @click="$emit('on-click')"
          class="header  p-2 flex align-center pointer justify-between"
          :style="[ !active ? {background: bgColor}: '']"
        >
          <p class="text-base text-uppercase font-bold"> {{ title }} </p>
          <div class="action flex">
            <md-icon class="ml-4" :class="iconClasses">{{ !active ? 'add': 'remove'}}</md-icon>
          </div>
        </div>
        <div class="body" v-if="active">
          <div class="item p-1 m-2">
           <slot></slot>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: () => false
    },
    bgColor: {
      type: String,
      default: () => '#e0e0e0'
    },
    borderClasses: {
      type: String,
      default: 'outline-gray-400 rounded'
    },
    iconClasses: {
      type: String,
      default: null,
    }
  }
}
</script>

<style>

</style>