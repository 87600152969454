<template>
    <div :class="customClass">
        <img class="rounded-lg" :style="{height: imageHeight + 'vh', width: 100 + '%'}"  :src="imageUrl"/>
    </div>
</template>

<script>
    export default {
    props: {
        customClass: {
            type: String
        },
        imageHeight: {
            type: Number,
            default: 50
        },
        imageUrl: {
            type: String,
            default: "https://img.jakpost.net/c/2020/03/01/2020_03_01_87874_1583031914._large.jpg"
        }
    },
    methods: {

    }
    }
</script>