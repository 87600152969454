<template>
    <div>
        <div class="md-layout-item md-size-100">
            <h2 class="text-black text-bold mb-4">Why This Class Important?</h2>
            <p class="text-justify text-sm line-height-27" v-html="item?.why_important"></p>
        </div>

        <div>
            <ImageSection  customClass="md-layout-item md-size-100 mt-5" :imageUrl="item?.thumbnail"/>
        </div>
    </div>
</template>

<script>
import ImageSection from "@/components/molecule/online-class/student/online-class/ImageSection.vue";

export default {
    name: 'Why This Class Important',
    components: {
        ImageSection,
    },
    props: {
        item: {
            type: Object,
            default: null,
        }
    }
}
</script>