<template>
    <div>
        <div class="md-layout-item md-size-100">
            <h2 class="text-black text-bold mb-4">Class Description</h2>
            <p class="text-justify text-sm line-height-27" v-html="item?.description"></p>
        </div>

        <div v-if="item?.intro_video">
            <Videos :height="50" :video_resource_path="item.intro_video"/>
        </div>
    </div>
</template>

<script>
import Videos from "@/components/molecule/online-class/student/Video.vue";

export default {
    name: 'Class Description',
    components: {
        Videos,
    },
    props: {
        item: {
            type: Object,
            default: null,
        }
    }
}
</script>