<template>
<div class="studen_online_class_details">
    <div
      class="rounded-lg align-center p-5 banner-image"
      :style="{backgroundImage: `linear-gradient(rgba(210, 203, 203, 0.75), rgba(0, 0, 0, 0.1)),url(${getClassDetail?.banner})`}"
    >
        <md-icon class="text-3xl mr-3 text-white banner-left-arrow cursor">arrow_back</md-icon>
        <div class="banner-text">
            <h1 class="text-4xl">{{ getClassDetail?.title }}</h1>
            <div class="flex align-center">
                <p class="text-sm mr-3 ">Tags</p>
                <div v-if="getClassDetail">
                    <span v-for="(tag, index) in getClassDetail.tags" :key="index" class="mr-3 font-light bg-trans-black px-2 py-1 rounded">#{{ tag }}</span>
                </div>
            </div>
        </div>

        <span class="banner-progress align-center flex justify-between">
            <p class="font-semibold">Completion Rate</p>
            <md-progress-bar class="rounded mx-2 w-32" md-mode="determinate" :md-value="getClassDetail && getClassDetail.completion_rate ? getClassDetail.completion_rate : 0"></md-progress-bar>
            <small class="text-sm font-bold" style="margin-top: -4px;">{{ getClassDetail && getClassDetail.completion_rate ? getClassDetail.completion_rate : 0 }}%</small>
        </span>
    </div>

    <SimpleTab 
        :items="items" 
        class="mt-8 flex justify-center border-b border-b-solid border-sky text-center w-100p"
        @activeTab="onSetTab"
    />

    <section class="w-100p">
        <div class="flex flex-row">
            <div class="w-25p">
                <div class="height-178 mx-height-178 mb-16 mt-6">
                    <Videos :height="26" :video_resource_path="getClassDetail?.intro_video"/>
                </div>
                
                <ClassOverview :item="getClassDetail"/>
            </div>

            <div v-if="selectedTab === 'Modules'" class="w-75p ml-8 mt-6">
                <div class="w-100p">
                    <Modules v-for="(item, index) in getClassDetail?.modules" :item="getClassDetail" :moduleObject="item" :key="index" :index="index" class="mb-2"/>
                </div>
            </div>

            <div v-else class="w-55p ml-8 mt-6">

                <div v-if="selectedTab === 'Class Description'">
                    <ClassDescription :item="getClassDetail" />
                </div>

                <div v-if="selectedTab === 'FAQ'">
                    <FaqView :item="getClassDetail" />
                </div>

                <div v-if="selectedTab === 'Why This Class Important?'">
                    <ClassWhyImportant :item="getClassDetail" />
                </div>
            </div>
        </div>
    </section>
    <div v-if="loading">
        <Loader />
    </div>
</div>
</template>

<script>
import SimpleTab from '@/components/atom/SimpleTab.vue';
import Videos from "@/components/molecule/online-class/student/Video.vue";
import ClassOverview from "@/components/molecule/online-class/student/online-class/ClassOverview.vue";
import Modules from "@/components/molecule/online-class/student/online-class/Modules.vue";
import ClassDescription from "@/components/molecule/online-class/student/online-class/ClassDescription.vue";
import ClassWhyImportant from "@/components/molecule/online-class/student/online-class/ClassWhyImportant.vue";
import FaqView from "@/components/molecule/online-class/student/online-class/FaqView.vue";
import Loader from '@/components/atom/loader.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    name: "ClassDetail",
    components: {
        SimpleTab,
        Videos,
        ClassOverview,
        Modules,
        ClassDescription,
        ClassWhyImportant,
        FaqView,
        Loader
    },
    data() {
        return {
            items: [
                {name: 'Modules', id: '1'},
                {name: 'Class Description', id: '2',},
                {name: 'FAQ', id: '3',},
                {name: 'Why This Class Important?', id: '4',},
            ],
            selectedTab: '',
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            getClassDetail: "onlineClassStudentPart/getClassDetail",
        }),
    },
    methods: {
        ...mapMutations({
			setShowSnackbar: "setShowSnackbar",
		}),
        ...mapActions({
            fetchModuleDetailsByClassId: "onlineClassStudentPart/fetchModuleDetailsByClassId",
        }),
        onSetTab(tab) {
            this.selectedTab = tab;
        }
    },
    created() {
        let classId= this.$route?.params?.id;

        if(classId) {
            this.loading = true;

            this.fetchModuleDetailsByClassId(classId)
            .then()
            .catch((error) => {
				console.log("error", error);
				this.setShowSnackbar(error);
			})
			.finally(() => this.loading = false);
        }
    }
};
</script>

<style lang="scss">
.tab-item-active {
    font-weight: bold;
    color: #ffff !important;
    border-bottom: 2px solid #265aef !important;
    margin-bottom: -2px;
    background-color: #265aef;
    text-align: center;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    padding-right: 12px;
}
</style>